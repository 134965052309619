import { Outlet, Route } from 'react-router-dom'
import QuizWrapper from './components/QuizWrapper'
import AnalyzedCompany from './pages/AnalyzedCompany'
import CompanyDocumentNumberAnalysis from './pages/CompanyDocumentNumberAnalysis'
import CreatedUser from './pages/CreatedUser'
import CreateUser from './pages/CreateUser'
import DeniedCompany from './pages/DeniedCompany'
import ExistingCompany from './pages/ExistingCompany'
import QuizFirstStep from './pages/QuizFirstStep'
import QuizSecondStep from './pages/QuizSecondStep'
import QuizThirdStep from './pages/QuizThirdStep'
import OnboardingState from './states/OnboardingState'
import OnboardingError from './pages/OnboardingError'
import AnalyzingCompany from './pages/AnalyzingCompany'
import PreApprovedCompany from './pages/PreApprovedCompany'
import DeniedUser from './pages/DeniedUser'
import UserAlreadyExists from './pages/UserAlreadyExists'
import PendingSecurityNumber from './pages/PendingSecurityNumber'
import Lead from './pages/Lead'

const OnboardingWrapper = () => (
  <OnboardingState>
    <Outlet />
  </OnboardingState>
)

export const OnboardingRouter = (
  <Route path="/onboarding" element={<OnboardingWrapper />}>
    <Route path="create-user" element={<CreateUser />} />
    <Route path="created-user" element={<CreatedUser />} />
    <Route path="analyzed-company" element={<AnalyzedCompany />} />
    <Route path="denied-company" element={<DeniedCompany />} />
    <Route path="quiz" element={<QuizWrapper />}>
      <Route path="step">
        <Route path="1/:documentNumber" element={<QuizFirstStep />} />
        <Route path="2/:documentNumber" element={<QuizSecondStep />} />
        <Route path="3/:documentNumber" element={<QuizThirdStep />} />
      </Route>
    </Route>
    <Route path="lead" element={<Lead />} />

    <Route path="company-document-number-analysis" element={<CompanyDocumentNumberAnalysis />} />
    <Route path="existing-company" element={<ExistingCompany />} />
    <Route path="onboarding-error" element={<OnboardingError />} />
    <Route path="analyzing-company/:id" element={<AnalyzingCompany />} />
    <Route path="pre-approved-company" element={<PreApprovedCompany />} />
    <Route path="denied-user" element={<DeniedUser />} />
    <Route path="user-already-exists" element={<UserAlreadyExists />} />
    <Route path="pending-security-number" element={<PendingSecurityNumber />} />
  </Route>
)
