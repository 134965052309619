import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import SecondaryInput from '../../../../components/inputs/SecondaryInput'
import H3 from '../../../common/components/Typography/H3'
import H5 from '../../../common/components/Typography/H5'
import Header from '../../components/Header'
import PageWrapper from '../../components/PageWrapper'
import * as yup from 'yup'
import * as validations from 'validations-br'
import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect } from 'react'
import { OnboardingContext } from '../../states/OnboardingContext'
import { useSearchParams, Link } from 'react-router-dom'

interface IProps {
  className?: string
}

interface ICreateUserForm {
  name: string
  email: string
  mobile: string
  documentNumber: string
}

const CreateUser: React.FC<IProps> = ({ className }: IProps) => {
  const { createUser, isCreatingUser } = useContext(OnboardingContext)
  const [searchParams] = useSearchParams()

  const schema = yup.object().shape({
    name: yup.string().required('Informe o seu nome completo'),
    email: yup.string().required('Informe o seu e-mail').email('Informe um e-mail válido'),
    mobile: yup
      .string()
      .required('Informe um celular válido')
      .test('is-phone', 'Telefone Inválido', (value?: string) => !!(value && validations.validatePhone(value))),
    documentNumber: yup
      .string()
      .required('Informe o seu CPF')
      .test('is-cpf', 'CPF inválido', (value?: string) => !!(value && validations.validateCPF(value)))
  })

  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm<ICreateUserForm>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const companyId = searchParams.get('companyId') as string
  const partnerId = searchParams.get('partnerId') as string

  useEffect(() => {
    localStorage.setItem('partnerId', partnerId)
  }, [partnerId])

  return (
    <PageWrapper className={className}>
      <Header />
      <div className="create-user__title___wrapper">
        <H3 className="create-user__title">Muito bom ter você aqui!</H3>
        <H5 className="create-user__subtitle">Primeiro passo para o cadastro da sua empresa é a gente se conhecer.</H5>
      </div>
      <form className="create-user__form" onSubmit={handleSubmit((data) => createUser({ companyId, ...data }))}>
        <div className="create-user__form___input____wrapper">
          <SecondaryInput
            label="Nome"
            {...register('name')}
            errorMessage={errors?.name?.message}
            data-testid="create-user-name-input"
          />
          <SecondaryInput
            label="CPF"
            {...register('documentNumber')}
            mask="###.###.###-##"
            errorMessage={errors?.documentNumber?.message}
            data-testid="create-user-document-number-input"
          />
          <SecondaryInput
            label="E-mail"
            {...register('email')}
            errorMessage={errors?.email?.message}
            data-testid="create-user-email-input"
          />
          <SecondaryInput
            label="Telefone"
            {...register('mobile')}
            mask="(##) #####-####"
            errorMessage={errors?.mobile?.message}
            data-testid="create-user-mobile-input"
          />
        </div>
        <footer className="create-user__form___footer">
          <p className="create-user__terms___text">
            Ao enviar seus dados, você está automaticamente concordando com a nossa{' '}
            <a rel="noreferrer" href="https://antecipafacil.com.br/politica-de-privacidade/" target="_blank">
              Politica de Privacidade
            </a>{' '}
            e{' '}
            <a
              rel="noreferrer"
              href="https://antecipa-production.s3.amazonaws.com/terms/Pol%C3%ADtica+de+Utiliza%C3%A7%C3%A3o+da+PLATAFORMA.pdf"
              target="_blank"
            >
              Termos de Uso
            </a>
            .
          </p>
          <div className="create-user__button___wrapper">
            <PrimaryButton data-testid="create-user-submit-test" type="submit" isLoading={isCreatingUser}>
              Enviar
            </PrimaryButton>
          </div>
          <p className="create-user__redirect-to-login">
            Já tem conta?
            <Link
              data-testid="redirect-to-login-test"
              className="create-user__redirect-to-login--link"
              to={companyId ? `/?companyId=${companyId}` : '/'}
            >
              Faça login aqui
            </Link>
          </p>
        </footer>
      </form>
    </PageWrapper>
  )
}

export default styled(CreateUser)`
  .create-user__title___wrapper {
    display: flex;
    flex-direction: column;

    gap: 8px;
    align-items: center;

    .create-user__title {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
    }

    .create-user__subtitle {
      color: ${({ theme: { colors } }) => colors?.quarternaryFont};
      text-align: center;
      width: 364px;
    }
  }

  .create-user__form {
    min-height: calc(100vh - 336px);

    width: 400px;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .create-user__form___input____wrapper {
      width: 100%;
    }

    .create-user__form___footer {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 32px;

      .create-user__terms___text {
        margin-top: 48px;
        width: 368px;
        font-size: 12px;
        line-height: 16px;
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        text-align: center;

        a {
          font-size: inherit;
          color: ${({ theme: { colors } }) => colors?.primary};
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .create-user__button___wrapper {
        width: 296px;
      }

      .create-user__redirect-to-login {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0;

        display: flex;
        gap: 8px;

        .create-user__redirect-to-login--link {
          text-decoration: underline;
          color: ${({ theme: { colors } }) => colors?.primary};
          cursor: pointer;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .create-user__form {
      width: 368px;
    }
  }
`
