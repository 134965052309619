import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputAdornment, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { Link, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import PrimaryButton from '../../buttons/PrimaryButton'
import PrimaryInput from '../../inputs/PrimaryInput'
import * as yup from 'yup'
import { useContext } from 'react'
import { AuthContext } from '../../../states/auth/AuthContext'
import { getErrorMessage } from '../../../utils/form'
import NormalForm from '../../auth/NormalForm'
import NormalFormWrapper from '../../auth/NormalFormWrapper'
import InputContainer from '../../inputs/InputContainer'

interface IProps {
  className?: string
}

const LoginForm: React.FC<IProps> = ({ className }: IProps) => {
  const { login, isLoggingIn, authError } = useContext(AuthContext)
  const [searchParams] = useSearchParams()
  const companyId = searchParams.get('companyId') as string

  const removeWhiteSpacesValidation = (password: string): boolean => {
    const removeWhiteSpaces = password.replace(/ /g, '')

    if (removeWhiteSpaces?.length > 0) return true

    return false
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },

    validationSchema: yup?.object({
      email: yup?.string().required('Digite um email'),
      password: yup
        ?.string()
        .required('Digite uma senha')
        .test(
          'is-empty',
          'Digite uma senha válida',
          (value?: string) => !!(value && removeWhiteSpacesValidation(value))
        )
    }),
    onSubmit: ({ email, password }) => login(email, password, companyId)
  })

  return (
    <NormalFormWrapper className={className}>
      <Typography variant="h5">Entrar com e-mail</Typography>
      <NormalForm onSubmit={formik.handleSubmit}>
        <div className="login-form-input-wrapper">
          <InputContainer>
            <PrimaryInput
              value={formik.values.email}
              errorMessage={getErrorMessage(authError, ['login', 'email'], formik)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              label="E-mail"
              type="email"
              placeholder="E-mail"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputAdornment>
                )
              }}
              data-testid="login-email-input"
            />
          </InputContainer>
          <InputContainer>
            <PrimaryInput
              value={formik.values.password}
              errorMessage={getErrorMessage(authError, ['login', 'password'], formik)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password"
              label="Senha"
              type="password"
              placeholder="Senha"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faLock} />
                  </InputAdornment>
                )
              }}
              data-testid="login-password-input"
            />
          </InputContainer>
        </div>
        <div className="login-form-remember-password-wrapper">
          <div data-testid="login-forgot-password-test">
            <Link to="/forgot-password">
              <Typography variant="h6">Lembrar minha senha</Typography>
            </Link>
          </div>
        </div>
        <PrimaryButton data-testid="login-submit-button" color="primary" type="submit" isLoading={isLoggingIn}>
          Entrar
        </PrimaryButton>
      </NormalForm>
      <p data-testid="login-create-account-right-link-test">
        Ainda não é cliente?{' '}
        <Link to="/onboarding/lead">
          <b>Criar conta</b>
        </Link>
      </p>
    </NormalFormWrapper>
  )
}

export default styled(LoginForm)`
  height: auto;
  a {
    text-decoration: none;
  }
  .login-form-input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 180px;
  }

  .login-form-remember-password-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
    width: 100%;

    h6 {
      font-size: 14px;
      font-weight: bold;
      color: ${({ theme }) => theme?.colors?.primaryFont};
    }

    a {
      text-decoration: none;
    }
  }

  b {
    font-weight: 600;
    color: ${({ theme }) => theme?.colors?.primary};
  }
`
