import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import SecondaryInput from '../../../../components/inputs/SecondaryInput'
import * as yup from 'yup'
import Header from '../../components/Header'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import PageWrapper from '../../components/PageWrapper'
import H3 from '../../../common/components/Typography/H3'
import axios from 'axios'
import * as validations from 'validations-br'
import { removeAllLettersAndSpecialCharacters } from '../../../../utils/string'
import { useState } from 'react'

interface IAnalyzingCompanyProps {
  className?: string
}

interface IAnalyzeCompanyForm {
  name: string
  surname: string
  phone: string
  email: string
  companyName: string
  documentNumber: string
}
const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório'),
  surname: yup.string().required('Este campo é obrigatório'),
  phone: yup.string().required('Este campo é obrigatório'),
  email: yup.string().required('Este campo é obrigatório').email('E-mail inválido'),
  companyName: yup.string().required('Este campo é obrigatório'),
  documentNumber: yup
    .string()
    .test(
      'is-cnpj',
      'Confira se o CNPJ está digitado corretamente.',
      (value?: string) => !!(value && validations.validateCNPJ(value))
    )
})

const Lead: React.FC<IAnalyzingCompanyProps> = ({ className }: IAnalyzingCompanyProps) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IAnalyzeCompanyForm>({
    resolver: yupResolver(schema)
  })

  async function createLead(data: IAnalyzeCompanyForm) {
    setLoading(true)
    fetch('https://3p2ic5clicb5afq4i6b7oax45e0uinif.lambda-url.us-east-1.on.aws/', {
      headers: {
        accept: 'application/json, text/plain, */*'
      },
      body: JSON.stringify({
        ...data,
        phone: removeAllLettersAndSpecialCharacters(data.phone),
        documentNumber: removeAllLettersAndSpecialCharacters(data.documentNumber)
      }),
      method: 'POST',
      mode: 'no-cors'
    })
      .then((response) => {
        navigate(
          '/onboarding/company-document-number-analysis?cnpj=' +
            removeAllLettersAndSpecialCharacters(data.documentNumber)
        )
      })
      .catch((e) => {
        console.log(e)
        navigate('/onboarding/onboarding-error')
      })
      .finally(() => setLoading(false))
  }

  return (
    <PageWrapper className={className}>
      <Header />
      <div className="company_document_container">
        <H3>Bem-vindo ao nosso simulador!</H3>
        <p className="company_document__welcome_text">
          Para uma <span>análise</span> da sua empresa, comece informando o seu CNPJ.
        </p>
        <form onSubmit={handleSubmit((data) => createLead(data))}>
          <SecondaryInput
            className={'company_document___input'}
            label="Nome"
            {...register('name')}
            errorMessage={errors?.name?.message}
          />
          <SecondaryInput
            className={'company_document___input'}
            label="Sobrenome"
            {...register('surname')}
            errorMessage={errors?.surname?.message}
          />
          <SecondaryInput
            className={'company_document___input'}
            label="E-mail"
            {...register('email')}
            errorMessage={errors?.email?.message}
          />
          <SecondaryInput
            className={'company_document___input'}
            label="Telefone"
            mask="(##) #####-####"
            {...register('phone')}
            errorMessage={errors?.phone?.message}
          />
          <SecondaryInput
            className={'company_document___input'}
            label="Nome da empresa"
            {...register('companyName')}
            errorMessage={errors?.companyName?.message}
          />
          <SecondaryInput
            className={'company_document___input'}
            label="CNPJ"
            mask="##.###.###/####-##"
            {...register('documentNumber')}
            errorMessage={errors?.documentNumber?.message}
            data-testid="documentNumber-input-test"
          />

          <PrimaryButton type="submit" isLoading={loading} data-testid="documentNumber-button-test">
            Enviar
          </PrimaryButton>
        </form>
      </div>
    </PageWrapper>
  )
}

export default styled(Lead)`
  .company_document_container {
    max-width: 400px;
    text-align: center;
    width: 100%;

    h3 {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 8px;

      @media (max-width: 500px) {
        padding: 8px 16px;
      }
    }

    button {
      max-width: 296px;
    }

    .company_document___input {
      max-width: 400px;
      padding-bottom: 16px;
      width: 100%;

      @media (max-width: 500px) {
        margin: auto;
        max-width: 368px;
      }
    }

    .company_document__welcome_text {
      padding: 0px 16px 44px;

      span {
        color: ${({ theme: { colors } }) => colors?.primary};
        font-weight: bold;
      }
    }
    .company_document____divisor-container {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 24px 0px;

      .company_document_____divisor {
        border: 1px solid;
        color: ${({ theme }) => theme?.colors?.gray?.lighter};
        width: 80px;
      }

      .company_document______divisor-text {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        font-size: 14px;
        margin: 0px 16px;
      }
    }

    .company_document_______bottom-text {
      padding: 0px 40px;

      span {
        color: ${({ theme: { colors } }) => colors?.primary};
        font-weight: bold;
      }
    }

    .company_document________bottom-text-info {
      color: ${({ theme }) => theme?.colors?.gray?.light};
      font-size: 12px;
      padding-top: 16px;

      span {
        color: ${({ theme }) => theme?.styleGuide?.support?.red};
        font-size: 12px;
      }

      @media (max-width: 500px) {
        padding: 16px 16px;
      }
    }

    .company_document_________redirect-text {
      display: flex;
      justify-content: center;
      padding-top: 90px;

      p {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        font-size: 14px;
        padding-right: 8px;
      }

      span {
        color: ${({ theme: { colors } }) => colors?.primary};
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 40px 0px;
    height: 100%;
  }
`
